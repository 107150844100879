<template>
  <div class="main">
    <heads></heads>
    <banners></banners>

    <div class="cons">
      <div class="tijiao"><el-button type="primary" @click="comeback">重新提交</el-button></div>

      <div class="ztflhtitle">
        <div class="ztflhtitle-list">标题：{{ztflhobj.btword}}</div>
        <div class="ztflhtitle-list">摘要：{{ztflhobj.zyword}}</div>
        <div class="ztflhtitle-list">关键字：{{ztflhobj.kwword}}</div>
      </div>


      <el-table :data="ztflhlist" border style="width: 100%">
        <!-- <el-table-column prop="flhname" label="名称" width="250" /> -->
        <el-table-column prop="flh" label="质量" />
        <el-table-column prop="idf" sortable label="idf" />
        <el-table-column label="操作">
            <template #default="scope">
              <el-button
                size="small"
                type="danger"
                @click="handleEdit(scope.row.flh)"
                >查看过程</el-button
              >
            </template>
          </el-table-column>
      </el-table>
    </div>

    <footers></footers>
  </div>
</template>

<script setup>
import { reactive, ref, getCurrentInstance } from "vue";
import { ElMessage } from "element-plus";
import { get } from "@/utils/index";
import { useRouter } from "vue-router";
import { constant } from "lodash";
const router = useRouter();
const { proxy } = getCurrentInstance();

// 中图分类号数据
let ztflhlist=reactive([])
let ztflhobj=reactive({})
let ids=ref('')

testLog();
// 获取数据
function testLog() {
  get("/journalRecommend/GetBynid", {
    id: router.currentRoute.value.query.id,
  }).then((res) => {
    Object.assign(ztflhobj,res.data)
    ztflhlist.push(...JSON.parse(res.data.ztflhstr))
    ids.value=res.data.Id
  });
}
// 查看过程
const handleEdit=flh=>{
  const url = router.resolve({
    path: "/wbcc/gc",
    query: {
      id:ids.value,
      flh: flh,
    },
  });
  window.open(url.href);
}
// 返回
const comeback=()=>{
  window.history.go(-1)
}
</script>

<style scoped>
.main {
  background: #fff;
}
.cons {
  width: 1200px;
  margin: auto;
  padding: 5px 0 50px 0;
  font-size: 14px;
}
.ztflhtitle-list{
  background: #f2f2f2;
  padding: 10px 20px;
  margin: 10px 0;
}
.tijiao{
  padding: 10px 0 0 0;
}
</style>
